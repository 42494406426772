/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const WorkingExperience = () => {
  const data = useStaticQuery(graphql`
    query WorkingQuery {
      site {
        siteMetadata {
          working {
            company
            title
            time
            location
          }
        }
      }
    }
  `)

  const workings: any[] = data.site.siteMetadata.working

  return (
    <div className="items-center mb-4 mt-8">
      <h1 className="pb-8">Working Experience</h1>
      <div className="table w-full">
        {workings.map((work, i) => (
          <div className="table-row-group" key={`work-${i}`}>
            <div className="table-cell text-gray-400 text-xs">{work.time}</div>
            <div className="table-cell">
              <div className="flex flex-col">
                <div>{work.title}</div>
                <div className="text-gray-300 text-xs pt-1 pb-6">{work.company} · {work.location}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WorkingExperience
